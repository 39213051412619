.content {
  margin: 80px auto;
  max-width: 1120px;

  h3 {
    width: 100%;
    color: var(--darkgray);
    font-weight: 600;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 20px auto 40px;

      @media (max-width: 745px) {
        margin: 20px auto;
      }
    }
  }

  .info {
    margin-bottom: 40px;

    .text {
      font-size: 18px;
      margin-bottom: 20px;

      @media (max-width: 745px) {
        font-size: 14px;
      }
    }
  }

  form {
    max-width: 700px;

    .item {
      margin-bottom: 20px;

      .box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        color: var(--gray);

        .input {
          padding: 8px;
          border-radius: 3px;
          border: 1px solid var(--lightgray);
        }
      }

      .red {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        color: red;

        .input {
          padding: 8px;
          border-radius: 3px;
          border: 1px solid red;
        }
      }
    }

    .errors {
      font-size: 14px;
      color: red;
    }

    .btn {
      text-transform: uppercase;
      padding: 10px 15px;
      background-color: var(--blue);
      color: var(--white);
      border: none;
      margin-top: 20px;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        background-color: var(--gray);
      }

      &:disabled {
        opacity: .5;
        cursor: not-allowed;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 40px auto;
  }

  @media (max-width: 1160px) {
    margin: 40px 20px;
  }

  @media (max-width: 745px) {
    margin: 20px;
  }
}