.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  max-width: 1120px;
  margin: 80px auto;

  .bigest {
    text-align: center;
    max-width: 800px;
    text-transform: uppercase;
    font-weight: 900;
    color: var(--darkgray);
    font-size: 48px;
    margin-bottom: 20px;

    @media (max-width: 745px) {
      max-width: 350px;
      font-size: 24px;
    }
  }

  .text {
    font-size: 21px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  a {
    text-decoration: none;
    color: var(--blue);

    &:hover {
      color: #195c78;
    }
  }

  .link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 245px;
    cursor: pointer;

    .text {
      font-size: 18px;
      font-weight: 600;
    }

    img {
      height: 24px;
    }

    @media (max-width: 300px) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      margin: 0 20px;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 20px auto 20px;
  }

  @media (max-width: 1160px) {
    margin: 80px 20px 40px;
  }

  @media (max-width: 745px) {
    margin: 20px;
  }
}