.content {
  max-width: 1120px;
  margin: 0 auto 80px;
  text-align: center;
  color: var(--darkgray);

  h3 {
    font-weight: 600;
    margin-bottom: 20px;
  }

  .textOne {
    font-size: 18px;
    margin-bottom: 40px;
  }

  .text {
    font-size: 18px;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 40px auto 40px;
    }
  }

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 40px auto;
  }

  @media (max-width: 860px) {
    margin: 0 20px 40px;

    br {
      display: none;
    }
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;
  }
}