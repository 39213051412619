.field {
  display: block;
  width: 100%;
  padding: 8px 16px;
  line-height: 25px;
  font-size: 14px;
  font-weight: 500;
  font-family: inherit;
  border-radius: 5px;
  -webkit-appearance: none;
  color: var(--darkgray);
  border: 1px solid var(--lightgray);
  background: var(--white);
  -webkit-transition: border .3s ease;
  -o-transition: border .3s ease;
  transition: border .3s ease;

  &::-webkit-input-placeholder {
    color: var(--gray);
  }

  &::-moz-placeholder {
    color: var(--gray);
  }

  &:-ms-input-placeholder {
    color: var(--gray);
  }

  &::-ms-input-placeholder {
    color: var(--gray);
  }

  &::placeholder {
    color: var(--gray);
  }

  &:focus {
    outline: none;
    border-color: var(--darkgray);
  }
}

.group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;

  &>span,
  .field {
    white-space: nowrap;
    display: block;

    &:not(:first-child):not(:last-child) {
      border-radius: 0;
    }

    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }

    &:not(:first-child) {
      margin-left: -1px;
    }
  }

  .field {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-top: 0;
    margin-bottom: 0;
  }

  &>span {
    text-align: center;
    width: 50px;
    font-size: 14px;
    line-height: 25px;
    color: var(--white);
    background: var(--blue);
    border: 1px solid var(--blue);
    -webkit-transition: background .3s ease, border .3s ease, color .3s ease;
    -o-transition: background .3s ease, border .3s ease, color .3s ease;
    transition: background .3s ease, border .3s ease, color .3s ease;
    cursor: pointer;
  }

  &:focus-within {
    &>span {
      color: var(--white);
      background: var(--gray);
      border-color: var(--gray);
    }
  }

  .loupe {
    position: absolute;
    top: 10px;
    margin-left: -10px;
  }

  @media (max-width: 745px) {
    margin-top: 20px;
  }
}