.blue {
  background-color: var(--blue);
  text-transform: uppercase;
  color: var(--white);
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;

  &:hover {
    background-color: var(--gray);
  }
}

.gray {
  background-color: var(--darkgray);
  text-transform: uppercase;
  color: var(--white);
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;

  &:hover {
    background-color: #555a5f;
  }
}

.browse {
  background-color: var(--blue);
  text-transform: uppercase;
  color: var(--white);
  font-size: 18px;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  cursor: pointer;
  margin-top: 40px;

  &:hover {
    background-color: var(--gray);
  }

  @media (max-width: 1160px) {
    margin-top: 0;
  }
}