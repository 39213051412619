.pattern {
  position: relative;
  width: 350px;
  max-height: 286px;
  background-color: var(--grayBack2);
  color: var(--darkgray);
  cursor: pointer;
  margin-bottom: 10px;

  img {
    width: 100%;
  }

  .overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    -webkit-transition: .5s ease;
    -o-transition: .5s ease;
    transition: .5s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center;

    .description {
      display: block;
      margin: 60px 0 40px;

      .title {
        color: var(--darkgray);
        font-size: 21px;
        text-transform: uppercase;
        font-weight: 600;

      }

      .subtitle {
        font-weight: 600;
        color: var(--gray);
        font-size: 14px;
      }
    }

    .price {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--gray);
      margin-bottom: 40px;

      @media (max-width: 390px) {
        margin-bottom: 20px;
      }
    }

  }

  &:hover .overlay {
    height: 100%;
  }

  @media (max-width: 390px) {
    width: 100%;
    min-height: 240px;
    padding: 0 0 12px;
  }
}