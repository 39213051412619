.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto 80px;

  h3 {
    width: 100%;
    color: var(--darkgray);
    font-weight: 600;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 20px auto 40px;

      @media (max-width: 745px) {
        margin: 20px auto;
      }
    }
  }

  .navigation {
    position: relative;
    width: 100%;
    margin-bottom: 20px;

    ul {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;

      @media (max-width: 1160px) {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }

      @media (max-width: 414px) {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
      }
    }

    li {
      font-size: 21px;
      list-style-type: none;
      background-color: transparent;
      padding: 8px 20px;
      border-radius: 5px;
      border: 2px solid var(--lightgray);
      margin: 0 40px 20px 0;
      cursor: pointer;
    }

    .item:hover {
      background-color: var(--lightgray);
    }

    .active {
      font-size: 21px;
      color: var(--white);
      background-color: var(--blue);
      border-color: var(--blue);
      padding: 8px 20px;
      border-radius: 5px;
    }
  }

  .patterns {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 40px;

    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 816px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 80px auto 40px;

    @media (max-width: 1160px) {
      margin: 20px 20px;
    }
  }

  @media (max-width: 1160px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px 40px;

    h3 {
      text-align: center;
    }
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;
  }
}