.color {
  margin-bottom: 80px;

  .text {
    width: 100%;
    font-size: 18px;
    margin: 0 0 20px;

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }

    @media (max-width: 745px) {
      margin-bottom: 20px;
    }
  }

  .preview {
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-bottom: 20px;

    img {
      @media (max-width: 1160px) {
        width: 100%;
      }
    }

    @media (max-width: 816px) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card {
      position: relative;
      cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        overflow: hidden;
        width: 100%;
        height: 0;
        -webkit-transition: .5s ease;
        -o-transition: .5s ease;
        transition: .5s ease;
      }

      &:hover .overlay {
        height: 100%;
      }
    }
  }

  @media (max-width: 1120px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
  }
}