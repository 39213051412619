:root {
	--black: #000000;
	--white: #FFFFFF;
	--blue: #52beeb;
	--darkgray: #3d4247;
	--gray: #787878;
	--lightgray: #c3c3c3;
	--grayBack: #e5e5e5;
	--grayBack2: #f6f6f6;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-SemiBoldItalic.eot');
	src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
		url('../public/fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-LightItalic.eot');
	src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
		url('../public/fonts/OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-LightItalic.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-Bold.eot');
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
		url('../public/fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot');
	src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-Light.eot');
	src: local('Open Sans Light'), local('OpenSans-Light'),
		url('../public/fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-Italic.eot');
	src: local('Open Sans Italic'), local('OpenSans-Italic'),
		url('../public/fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-Regular.eot');
	src: local('Open Sans Regular'), local('OpenSans-Regular'),
		url('../public/fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-SemiBold.eot');
	src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
		url('../public/fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-ExtraBold.eot');
	src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Open Sans';
	src: url('../public/fonts/OpenSans/OpenSans-BoldItalic.eot');
	src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
		url('../public/fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../public/fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
		url('../public/fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

.wrapper {
	margin: 0 auto;
	min-height: calc(100vh - 454px);

	@media (max-width: 745px) {
		min-height: calc(100vh - 470px);
	}
}

h1 {
	font-size: 40px;

	@media (max-width: 745px) {
		font-size: 24px;
	}
}

h2 {
	font-size: 24px;
}

h3 {
	font-size: 21px;
}

.quote {
	color: var(--white);
	text-align: center;
	padding: 60px;
	background-image: url('./assets/img/Background.png');
	margin-bottom: 80px;

	h1 {
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h3 {
		font-weight: 600;
		margin-bottom: 20px;

		@media (max-width: 745px) {
			font-size: 16px;
		}
	}

	.info {
		text-align: center;
		max-width: 840px;
		font-weight: 600;
		margin: 0 auto 20px;

		@media (max-width: 745px) {
			font-size: 14px;
		}
	}

	@media (max-width: 1120px) {
		margin-bottom: 40px;
	}

	@media (max-width: 890px) {
		br {
			display: none;
		}
	}

	@media (max-width: 745px) {
		padding: 20px;
		margin-bottom: 20px;
	}
}

.quoteStore {
	color: var(--white);
	text-align: center;
	padding: 80px 20px 40px;
	background-image: url('./assets/img/Background.png');
	margin-bottom: 80px;

	h1 {
		text-transform: uppercase;
		font-weight: 400;
		margin-bottom: 20px;
	}

	h3 {
		font-weight: 600;
		margin-bottom: 20px;

		@media (max-width: 745px) {
			font-size: 16px;
		}
	}

	.info {
		text-align: center;
		max-width: 820px;
		font-weight: 600;
		margin: 0 auto 20px;

		@media (max-width: 860px) {
			font-size: 14px;
		}
	}

	@media (max-width: 1120px) {
		margin-bottom: 40px;
	}

	@media (max-width: 890px) {
		br {
			display: none;
		}
	}

	@media (max-width: 745px) {
		padding: 60px 20px 20px;
		margin-bottom: 20px;
	}
}