.attention {
  margin: 20px 0 80px;
  font-size: 18px;

  .bigger {
    margin: 20px 0;
  }

  .first {
    margin-bottom: 10px;
  }

  @media (max-width: 1140px) {
    margin: 20px 0 40px;
  }

  @media (max-width: 1140px) {
    margin: 20px 0;
  }
}

.pack {
  margin-bottom: 80px;

  img {
    max-width: 480px;
    width: 100%;
  }

  @media (max-width: 1140px) {
    margin: 20px 0 40px;
  }

  @media (max-width: 1140px) {
    margin: 20px 0;
  }
}