.gif {
  width: 100%;
}

.video {
  max-width: 1120px;
  width: 100%;
  max-height: 650px;
  height: 100%;
}

.text {
  font-size: 18px;
  margin: 20px 0 80px;

  @media (max-width: 1160px) {
    margin-bottom: 40px;
  }
}

.youtube {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}