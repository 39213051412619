footer {
  .blue {
    text-align: center;
    background-color: var(--blue);
    color: var(--darkgray);

    .content {
      display: block;
      padding: 5px 0 20px;
      margin: 0 20px;

      h2 {
        margin-top: 20px;
        font-size: 36px;
        text-transform: uppercase;
        font-weight: 200;
        margin: 20px auto;

        @media (max-width: 745px) {
          font-size: 24px;
        }
      }

      p {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;
        margin: 20px 0;
      }

      .media {
        margin: 0 auto;
        width: 112px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        svg {
          cursor: pointer;

          &:hover {
            opacity: 0.8;
            background-color: var(--white);
            border-radius: 50%;
            border: none;
          }
        }
      }
    }
  }

  .gray {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: var(--lightgray);
    background-color: var(--darkgray);
    font-size: 14px;
    padding: 20px;

    .navigation {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .item {
        margin: 0 20px 20px;
        font-size: 14px;
        font-weight: 600;
        text-decoration: none;
        color: var(--lightgray);
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          color: var(--gray);
        }

        @media (max-width: 745px) {
          font-size: 12px;
          margin: 0 20px 10px;
        }
      }

      @media (max-width: 745px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
    }

    .copyright {
      text-align: center;
      margin-bottom: 20px;
    }

    .host {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      .iPage {
        margin-left: 10px;

        @media (max-width: 745px) {
          margin-left: 5px;

          img {
            height: 16px;
          }
        }

        &:hover {
          opacity: .8;
        }
      }
    }

    @media (max-width: 745px) {
      font-size: 12px;
    }
  }
}