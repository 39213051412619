.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto;

  .products {
    display: grid;
    display: -ms-grid;    
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 20px;

    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 816px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 80px auto 40px;

    @media (max-width: 1160px) {
      margin: 20px auto;
    }
  }

  @media (max-width: 1160px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
  }
}

.forecast {
  text-align: center;
  color: var(--gray);
  font-weight: 600;
  margin-bottom: 80px;

  @media (max-width: 1160px) {
    margin-bottom: 40px;
  }
}