header {
  position: sticky;
  top: 0;
  background-color: var(--darkgray);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  color: var(--lightgray);
  z-index: 10;

  .menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    max-width: 1120px;
    margin: 0 auto;
  }

  .navigation {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    .item {
      color: var(--lightgray);
      text-decoration: none;
      margin: 0 20px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        color: var(--gray);
      }

      @media (max-width: 745px) {
        margin-bottom: 20px;
      }
    }

    @media (max-width: 745px) {
      position: fixed;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding: 0;
      z-index: 10;
      right: 0;
      top: 0;
      bottom: 0;
      left: -100%;
      width: 100%;
      height: 100vh;
      -webkit-transition: left 1s;
      -o-transition: left 1s;
      transition: left 1s;
      margin: 40px;
    }
  }

  .burger {
    display: none;
    position: absolute;
    right: 20px;
    top: 10px;

    @media (max-width: 745px) {
      display: block;
      z-index: 10;
      cursor: pointer;
    }
  }

  .active {
    background-color: var(--darkgray);
    left: 0;

    @media (max-width: 745px) {
      margin: 39px 0 0;
      height: 160px;
    }
  }
}