.content {
  max-width: 1120px;
  margin: 0 auto 80px;

  .item {
    margin-bottom: 40px;

    .title {
      margin-top: 80px;
      text-transform: uppercase;
      color: var(--darkgray);

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--grayBack2);
        margin: 20px auto 20px;
      }

      @media (max-width: 745px) {
        margin-top: 20px;
      }
    }

    .info {
      margin-bottom: 80px;

      h4 {
        text-transform: uppercase;
        margin: 20px 0 10px;
      }

      .list {
        margin-bottom: 20px;
      }

      li {
        font-size: 18px;
        list-style-type: none;
      }

      .text {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        font-size: 18px;

        a {
          color: var(--blue);
          text-decoration: none;
          cursor: pointer;
        }
      }

      @media (max-width: 745px) {
        margin-bottom: 20px;
      }
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 20px auto 20px;
  }

  @media (max-width: 1160px) {
    margin: 0 20px 40px;
  }
}