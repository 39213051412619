.specification {
  margin: 0 auto;
  font-size: 14px;

  h2 {
    text-align: center;
    letter-spacing: 0.2em;
    color: var(--gray);
    text-transform: uppercase;
    margin-bottom: 80px;

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }

    @media (max-width: 745px) {
      text-align: left;
      font-size: 18px;
    }
  }

  .list {
    margin-bottom: 80px;

    .item {
      width: 800px;
      margin: 0 auto;

      .characteristic {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        .denotation {
          font-weight: 600;
          color: var(--gray);
          margin-right: 20px;
        }

        .properties {
          text-align: right;
          font-weight: 600;
        }
      }

      &::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: var(--grayBack2);
        margin: 20px 0 20px;
      }

      &:last-child::after {
        content: none;
        margin: 0;
      }

      @media (max-width: 840px) {
        width: 100%;
      }

      @media (max-width: 320px) {
        max-width: 280px;
      }
    }

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }
  }
}