.pattern {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 368px;
  height: 300px;
  background-color: var(--grayBack2);
  color: var(--darkgray);
  cursor: pointer;

  img {
    max-width: 368px;
    width: 100%;
    max-height: 208px;
  }

  .description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-width: 328px;
    width: 100%;
    margin: 0 20px 20px;

    .title {
      font-size: 18px;
      text-transform: capitalize;
      font-weight: 600;
    }

    p {
      font-size: 12px;
      font-weight: 700;
      color: var(--gray);
    }

    .price {
      width: 80px;
      text-align: center;
      background-color: var(--white);
      font-weight: bold;
      line-height: 2;
      text-transform: uppercase;
    }

    @media (max-width: 408px) {
      width: calc(100% - 40px);
      padding-top: 20px;
    }

    @media (max-width: 390px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      .price {
        margin-top: 10px;
      }
    }
  }

  .overlay {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.95);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    -webkit-transition: .5s ease;
    -o-transition: .5s ease;
    transition: .5s ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: center;

    .detail {
      display: block;
      margin: 30px 0;

      .title {
        color: var(--darkgray);
        font-size: 21px;
        text-transform: uppercase;
        font-weight: 600;

      }

      .subtitle {
        font-weight: 600;
        color: var(--gray);
        font-size: 14px;
      }
    }

    .price {
      text-transform: uppercase;
      font-weight: 600;
      color: var(--gray);
      margin-bottom: 30px;
    }

  }

  &:hover .overlay {
    height: 100%;
  }

  @media (max-width: 408px) {
    width: 100%;
    height: calc(100% - 20px);
    padding-bottom: 10px;
  }
}
