.main {
  max-width: 1120px;
  margin: 0 auto 80px;

  img {
    max-width: 100%;
  }

  @media (max-width: 1160px) {
    margin: 0 20px 40px;
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;
  }
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto 40px;

  .designation {
    width: 100%;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 20px auto 40px;

      @media (max-width: 745px) {
        margin: 20px auto;
      }
    }

    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;

      @media (max-width: 745px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
      }
    }

    h3 {
      width: 100%;
      color: var(--darkgray);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .patterns {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 20px;

    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 816px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
    text-decoration: none;
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 40px auto;

    @media (max-width: 1160px) {
      margin: 20px 20px 0;
    }
  }

  @media (max-width: 1160px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px 40px;
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;

    h3 {
      text-align: center;
    }
  }
}