.main {
  max-width: 1120px;
  margin: 0 auto 80px;
  background-color: var(--grayBack);
  color: var(--gray);
  text-align: center;

  .img {
    background-image: url('../../assets/img/mainForHome.jpg');
    background-color: var(--darkgray);
    max-width: 1120px;
    height: 325px;
    background-size: cover;
    background-repeat: no-repeat;

    @media (max-width: 745px) {
      height: 150px;
    }

    @media (max-width: 360px) {
      height: 80px;
    }
  }

  .block {
    max-width: 720px;
    padding: 20px;
    text-align: center;
    margin: 0 auto;

    h1 {
      font-weight: 600;
      margin-bottom: 10px;
      color: var(--gray);
    }

    p {
      font-weight: 600;
      margin-bottom: 20px;
    }

    .btn {
      font-size: 18px;
      text-transform: uppercase;
      padding: 10px 15px;
      color: var(--gray);
      border: 3px solid var(--gray);
      border-radius: 5px;
      background: transparent;
      cursor: pointer;

      &:hover {
        background: var(--gray);
        color: var(--white);
      }
    }
  }

  @media (max-width: 1160px) {
    margin: 0 20px 40px;
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;
  }
}

.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto 80px;

  .designation {
    width: 100%;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 20px auto 40px;

      @media (max-width: 745px) {
        margin: 20px auto;
      }
    }

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 745px) {
        flex-direction: column;
      }
    }

    h3 {
      text-align: left;
      width: 100%;
      color: var(--darkgray);
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: 745px) {
        text-align: center;
      }
    }
  }

  .patterns {
    display: grid;
    display: -ms-grid;    
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 20px;

    @media (max-width: 1160px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 816px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .browse {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;

    .btn {
      background-color: var(--blue);
      text-transform: uppercase;
      color: var(--white);
      font-size: 18px;
      border: none;
      border-radius: 5px;
      padding: 15px 30px;
      cursor: pointer;
      margin-top: 40px;

      &:hover {
        background-color: var(--gray);
      }

      @media (max-width: 1160px) {
        margin-top: 20px;
      }
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 40px auto;

      @media (max-width: 1160px) {
        margin: 20px 20px 0;
      }
    }
  }

  @media (max-width: 1160px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px 40px;

    h3 {
      text-align: center;
    }
  }

  @media (max-width: 745px) {
    margin: 0 20px 20px;
  }
}