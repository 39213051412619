.navigation {
  position: fixed;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--grayBack2);

  z-index: 1;

  .item {
    text-transform: uppercase;
    margin: 15px 20px;
    font-size: 14px;
    font-weight: 600;
    list-style-type: none;
    cursor: pointer;

    &:hover {
      color: var(--gray)
    }

    a {
      text-decoration: none;
      color: var(--darkgray);
    }

    @media (max-width: 745px) {
      margin: 10px 20px;
    }

    @media (max-width: 550px) {
      white-space: nowrap;
    }
  }

  @media (max-width: 745px) {
    overflow-y: auto;
  }

  @media (max-width: 550px) {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;

    // scrollbar-по горизонтали
    &::-webkit-scrollbar {
      width: 900px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border: 1px solid var(--gray);
      margin: 0;
      padding: 20px 0;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--blue);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: var(--red);
    }

  }
}