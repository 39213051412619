.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .box {
    width: 380px;
    height: 214px;
    background-color: #dedede;
    margin-bottom: 10px;

    img {
      width: 100%;
    }
  }

  .title {
    font-size: 21px;

    @media (max-width: 410px) {
      font-size: 18px;
    }

    @media (max-width: 340px) {
      font-size: 16px;
    }

    @media (max-width: 319px) {
      font-size: 14px;
    }
  }

  @media (max-width: 420px) {
    .box {
      max-width: 380px;
      width: 100%;
      max-height: 204px;
      height: 80%;

      img {
        width: 100%;
      }
    }
  }
}