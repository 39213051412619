.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 1120px;
  margin: 0 auto;

  h3 {
    width: 100%;
    color: var(--darkgray);
    font-weight: 600;
    text-transform: uppercase;

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: var(--grayBack2);
      margin: 20px auto 40px;

      @media (max-width: 745px) {
        margin: 20px auto;
      }
    }
  }

  .requirements {
    text-align: center;
    text-transform: uppercase;
    margin: 0 auto 80px;

    h2 {
      color: var(--gray);
      margin-bottom: 20px;
    }

    dt {
      font-weight: bold;
      margin: 0 0 20px;
    }

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }

    @media (max-width: 745px) {
      text-align: left;

    }
  }

  .music {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--gray);
    margin: 0 auto;
  }

  .exception {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    color: var(--gray);
    margin: 0 auto 80px;

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }
  }

  .download {
    text-decoration: none;
    color: var(--gray);
    cursor: pointer;

    &:hover {
      color: var(--blue);
    }
  }

  .btn {
    background-color: var(--blue);
    text-transform: uppercase;
    color: var(--white);
    font-size: 18px;
    border: none;
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    margin-bottom: 80px;

    &:hover {
      background-color: var(--gray);
    }

    @media (max-width: 1160px) {
      margin-bottom: 40px;
    }
  }

  .information {
    max-width: 1120px;
    margin-bottom: 80px;

    h4 {
      color: var(--gray);
      text-transform: uppercase;
      margin-bottom: 40px;
      text-align: center;


      @media (max-width: 745px) {
        text-align: left;
        margin-bottom: 20px;
      }
    }

    .text {
      width: 100%;
      max-width: 800px;
      text-align: center;

      &:last-child {
        margin-top: 20px;

        @media (max-width: 745px) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          text-align: left;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;
        }
      }

      .link {
        text-decoration: none;
        color: var(--blue);
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 391px) {
          max-width: 220px;
        }
      }

      @media (max-width: 745px) {
        text-align: left;
      }
    }
  }

  .learn {
    max-width: 1120px;
    text-align: center;

    h3::after {
      display: none;
    }

    h2 {
      color: (--gray);
      text-transform: uppercase;
      margin-bottom: 40px;

      @media (max-width: 745px) {
        margin-bottom: 20px;
      }
    }

    .video {
      max-width: 1120px;
      width: 100%;
      max-height: 650px;
      height: 100%;
    }

    @media (max-width: 745px) {
      text-align: left;
    }
  }

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--grayBack2);
    margin: 40px auto 80px;

    @media (max-width: 1160px) {
      margin: 20px auto 40px;
    }

    @media (max-width: 1160px) {
      margin: 20px auto 20px;
    }
  }

  @media (max-width: 1160px) {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 20px;
  }
}